import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { InternalHome, PublicHome } from "./constants";
import { ProvideAuth, useAuth } from "./auth";

const Public = lazy(() => import("./containers/Public"));
const Internal = lazy(() => import("./containers/Internal"));
const Success = lazy(() => import("./containers/Success"));
const Imprint = lazy(() => import("./containers/Imprint"));
const Privacy = lazy(() => import("./containers/Privacy"));
const Pkv = lazy(() => import("./containers/Pkv"));
const PkvNeukunden = lazy(() => import("./containers/forms/PkvNeukunden"));
const Checkup = lazy(() => import("./containers/forms/Checkup"));
const Estate = lazy(() => import("./containers/Estate"));
const Login = lazy(() => import("./containers/Login"));

function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const LazyRoute = ({ exact = false, path, component }) => (
  <Route exact={exact} path={path}>
    <Suspense fallback={<span />}>{React.createElement(component)}</Suspense>
  </Route>
);

const Router = () => {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <Switch>
          <LazyRoute exact path={PublicHome} component={Public} />
          <PrivateRoute exact path={InternalHome}>
            <Suspense fallback={<span />}>
              <Internal />
            </Suspense>
          </PrivateRoute>
          <LazyRoute
            exact
            path="/intern/formulare/pkv-neukunden"
            component={PkvNeukunden}
          />
          <LazyRoute
            exact
            path="/intern/formulare/checkup"
            component={Checkup}
          />
          <LazyRoute
            exact
            path="/private-krankenversicherung"
            component={Pkv}
          />
          <LazyRoute exact path="/immobilienfinanzierung" component={Estate} />
          <LazyRoute exact path="/impressum" component={Imprint} />
          <LazyRoute exact path="/datenschutz" component={Privacy} />
          <LazyRoute exact path="/success" component={Success} />
          <LazyRoute exact path="/login" component={Login} />
        </Switch>
      </BrowserRouter>
    </ProvideAuth>
  );
};

export default Router;
