import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Router from "./router";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#032449",
    },
    secondary: {
      main: "#1A9FDE",
    },
  },
});

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
