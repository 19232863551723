import React, { useState, useContext, createContext, useEffect } from "react";

const authContext = createContext();
const localStorageKey = "app-auth-token";

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = async (username, password) => {
    const response = await fetch("/api/auth/login", {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      method: "POST",
      body: JSON.stringify({
        username,
        password,
      }),
    });
    const { success, ...user } = await response.json();
    if (success) {
      setUser(user);
      localStorage.setItem(localStorageKey, user.token);
      return user;
    } else {
      throw new Error("Login failed");
    }
  };

  const signout = async () => {
    setUser(false);
    localStorage.removeItem(localStorageKey);
  };

  useEffect(() => {
    const restore = async () => {
      const token = localStorage.getItem(localStorageKey);
      if (token) {
        try {
          const response = await fetch("/api/auth/status", {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Authorization: `Bearer ${token}`,
            },
            method: "POST",
          });
          const { success, username } = await response.json();
          if (success) {
            setUser({ username, token });
          } else {
          }
        } catch (e) {
          localStorage.removeItem(localStorageKey);
        }
      }
    };
    restore();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
  };
}
